const moduleState = {
  questionAnswers: {
    1: null,
    2: null,
    3: null,
    4: null,
    5: null,
    6: null,
    7: null,
    8: null,
    9: null,
  },
  currentQuestion: 1,
  question2Textarea: null,
  question3Textarea: null,
  question5Textarea: null,
  question7Textarea: null,
  question8Textarea: {
    1: null,
    2: null,
  },
  peopleList: {
    q1no: [{
      name: '',
      benefit: '',
    }],
    q3no: [{
      name: '',
      benefit: '',
    }],
  },
};

const actions = {
  answerQuestion({ commit }, { questionId, answer }) {
    commit('answerQuestion', { questionId, answer });
  },
  setCurrentQuestion({ commit }, { questionId }) {
    commit('setCurrentQuestion', { questionId });
  },
  setQuestion2Textarea({ commit }, { value }) {
    commit('setQuestion2Textarea', { value });
  },
  setQuestion3Textarea({ commit }, { value }) {
    commit('setQuestion3Textarea', { value });
  },
  setQuestion5Textarea({ commit }, { value }) {
    commit('setQuestion5Textarea', { value });
  },
  setQuestion7Textarea({ commit }, { value }) {
    commit('setQuestion7Textarea', { value });
  },
  setQuestion8Textarea({ commit }, { index, value }) {
    commit('answerQuestion', { index, value });
  },
  addPerson({ commit }, { type }) {
    commit('addPerson', { type });
  },
  deletePerson({ commit }, { type, id }) {
    commit('deletePerson', { type, id });
  },
  updatePerson({ commit }, { type, id, prop, value }) {
    commit('updatePerson', { type, id, prop, value });
  },
};

const mutations = {
  answerQuestion(state, { questionId, answer }) {
    state.questionAnswers[questionId] = answer;
  },
  setCurrentQuestion(state, { questionId }) {
    state.currentQuestion = questionId;
  },
  setQuestion2Textarea(state, { value }) {
    state.question2Textarea = value;
  },
  setQuestion5Textarea(state, { value }) {
    state.question5Textarea = value;
  },
  setQuestion3Textarea(state, { value }) {
    state.question3Textarea = value;
  },
  setQuestion7Textarea(state, { value }) {
    state.question7Textarea = value;
  },
  setQuestion8Textarea(state, { index, value }) {
    state.question8Textarea[index] = value;
  },
  addPerson(state, { type }) {
    state.peopleList[type].push({
      name: '',
      benefit: '',
    });
  },
  deletePerson(state, { type, id }) {
    state.peopleList[type].splice(id, 1);
  },
  updatePerson(state, { type, id, prop, value }) {
    state.peopleList[type][id][prop] = value;
  },
};

export default {
  namespaced: true,
  state: moduleState,
  actions,
  mutations,
};
