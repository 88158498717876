const moduleState = {
  partners: [
    {
      name: null,
      role: null,
      organization: null,
      type: 0,
      purpose: 0,
      support: 0,
      benefit: 0,
      know: 0,
      contacted: null,
      meet: null,
    },
  ],
  typeOpts: ['Business', 'Civic', 'Nonprofit', 'Faith Community', 'Youth-serving Organization', 'Philanthropic Organization', 'Schools (K-12)', 'Higher Education', 'Other'],
  purposeOpts: ['Learn about their needs', 'Share info', 'Gather insights', 'Identify concerns', 'Invite to participate', 'Ask for support', 'Other'],
  supportOpts: ['Financial', 'Staff', 'Influence / credibility', 'Active partnership', 'Enable partnership', 'Leadership / core team', 'Other'],
  benefitOpts: ['Access to resources', 'Youth insights', 'Problem solving help', 'Improved information sharing', 'Rapid access to direct assistance', 'Willing to fill service gaps', 'Opportunity to see tangible progress', 'Other'],
  knowIntroOpts: ['Yes', 'No', 'Can get intro from...'],
  typeOther: [],
  purposeOther: [],
  supportOther: [],
  benefitOther: [],
  knowIntroOther: [],
};

const actions = {
  addPartner({ commit }) {
    commit('addPartner');
  },
  deletePartner({ commit }, { id }) {
    commit('deletePartner', { id });
  },
  updatePartner({ commit }, { id, prop, value }) {
    commit('updatePartner', { id, prop, value });
  },
  updateOther({ commit }, { type, id, value }) {
    commit('updateTypeOther', { type, id, value });
  },
};

const mutations = {
  addPartner(state) {
    state.partners.push({
      name: null,
      role: null,
      organization: null,
      type: 0,
      purpose: 0,
      support: 0,
      benefit: 0,
      know: 0,
      contacted: null,
      meet: null,
    });
  },
  deletePartner(state, { id }) {
    state.partners.splice(id, 1);
    if (state.typeOther[id] !== undefined) {
      state.typeOther.splice(id, 1);
    }
    if (state.purposeOther[id] !== undefined) {
      state.purposeOther.splice(id, 1);
    }
    if (state.supportOther[id] !== undefined) {
      state.supportOther.splice(id, 1);
    }
    if (state.benefitOther[id] !== undefined) {
      state.benefitOther.splice(id, 1);
    }
    if (state.knowIntroOther[id] !== undefined) {
      state.knowIntroOther.splice(id, 1);
    }
  },
  updatePartner(state, { id, prop, value }) {
    state.partners[id][prop] = value;
  },
  updateOther(state, { type, id, value }) {
    state[type][id] = value;
  },
};

export default {
  namespaced: true,
  state: moduleState,
  actions,
  mutations,
};
