const moduleState = {
  whereDidYouSleepLastNightOpts: [
    'Emergency or temporary shelter',
    'Transitional housing',
    'Authorized camp or village',
    'Tent or unauthorized camp',
    'Hotel or motel',
    'Home of person I\'m having sex with',
    'Friend\'s home',
    'Couchsurfing',
    'Car or other vehicle',
    'Camper/RV',
    'Abandoned building, vacant unit, squat',
    'Backyard shed or storage',
    'structure',
    'On a train or bus or in a train or bus station',
    '24-hour restaurant, laundromat or other business',
    '24-hour restaurant, laundromat or other business',
    'Outdoors (streets, parks, etc.)',
    'Hospital or emergency room',
    'Drug/alcohol treatment facility',
    'Juvenile detention center or jail',
    'My apartment or house',
    'Parent\'s or guardian\'s home',
    'Other relative\'s home',
    'Boyfriend/girlfriend\'s home',
    'Foster home',
    'Group home',
    'Other (Please specify)',
  ],
  contributingFactorsOpts: [
    'I didn\'t get along with my family',
    'I didn\'t follow the rules',
    'My family neglected me',
    'My family abused me',
    'I didn\'t feel safe',
    'I abused drugs or alcohol',
    'My parents/guardians abused drugs or alcohol',
    'My sexual orientation',
    'My gender identity',
    'My family was homeless',
    'Other (please explain)',
  ],
  helpfulSupportOpts: [
    'Help from social services',
    'Help with school',
    'Help with a job',
    'Medical care',
    'Drug treatment',
    'Individual counseling',
    'Family counseling',
    'Someone to advocate for me',
    'Peer support',
    'Transportation',
    'A place to stay',
    'Other',
  ],
  sleptInLastThreeYearsOpts: [
    'Emergency or temporary shelter',
    'Transitional housing',
    'Authorized camp or village',
    'Tent or unauthorized camp',
    'Hotel or motel',
    'Home of person I\'m having sex with',
    'Friend\'s home',
    'Couchsurfing',
    'Car or other vehicle',
    'Camper/RV',
    'Abandoned building, vacant unit, squat',
    'Backyard shed or storage',
    'structure',
    'On a train or bus or in a train or bus station',
    ' 24-hour restaurant, laundromat or other business',
    '24-hour restaurant, laundromat or other business',
    'Outdoors (streets, parks, etc.)',
    'Hospital or emergency room',
    'Drug/alcohol treatment facility',
    'Juvenile detention center or jail',
    'My apartment or house',
    'Parent\'s or guardian\'s home',
    'Other relative\'s home',
    'Boyfriend/girlfriend\'s home',
    'Foster home',
    'Group home',
    'Other (Please specify)',
  ],
  helpReceivedWasOpts: [
    'Very Helpful',
    'Somewhat Helpful',
    'Not at all helpful',
  ],
  genderIdentityOpts: [
    'Male',
    'Female',
    'Non-binary',
    'Other',
  ],
  raceOpts: [
    'White',
    'Black of African American',
    'American Indian or Alaskan Native',
    'Asian',
    'Native Hawaiian or Pacific Islander',
    'Other',
  ],
  sexualOrientationOpts: [
    'Straight',
    'Gay/Lesbian',
    'Bisexual',
    'Other',
  ],
  currentEmploymentStatusOpts: [
    'Employed full-time',
    'Employed part-time',
    'Unemployed',
  ],
  yesNoOpts: ['Yes', 'No'],
  howOldAreYou: null,
  haveYouEverBeenHomeless: 0,
  whereDidYouSleepLastNight: 0,
  sleptInLastThreeYears: [],
  contributingFactors: [],
  helpfulSupports: [],
  helpReceivedWas: 0,
  singleMostHelpfulSupport: null,
  singleLeastHelpfulSupport: null,
  goWhenNeedPlace: null,
  goWhenHungry: null,
  goWhenNeedSomething: null,
  adultToConfideIn: 0,
  whereMetAdult: null,
  genderIdentity: 0,
  race: [],
  hispanicLatino: 0,
  sexualOrientation: 0,
  diplomaOrGED: 0,
  currentlyInSchool: 0,
  employmentStatus: 0,
};

const actions = {
  updateProp({ commit }, { prop, value }) {
    commit('updateProp', { prop, value });
  },
};

const mutations = {
  updateProp(state, { prop, value }) {
    state[prop] = value;
  },
};

export default {
  namespaced: true,
  state: moduleState,
  actions,
  mutations,
};
