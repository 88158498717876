const emptyRosterRecord = {
  role: 0,
  name: null,
  jobTitle: null,
  organization: null,
};

const emptyBackboneRosterRecord = {
  organization: null,
  staff: null,
  adminServices: null,
};

const emptyPartnersRecord = {
  sector: 0,
  name: null,
  title: null,
  organization: null,
};

const emptyWorkGroupRecord = {
  role: 0,
  name: null,
  organization: null,
};
const moduleState = {
  rosterRoles: ['Core Team', 'Staff', 'Youth Action Council'],
  partnerSectors: ['Public', 'Private', 'Nonprofit', 'Education', 'Faith Community'],
  workGroupRoles: ['Chair', 'Member'],
  cityName: null,
  roster: [
    Object.assign({}, emptyRosterRecord),
  ],
  backboneRoster: [
    Object.assign({}, emptyBackboneRosterRecord),
  ],
  partnersRoster: [
    Object.assign({}, emptyPartnersRecord),
  ],
  workGroups: {
    1: [
      Object.assign({}, emptyWorkGroupRecord),
    ],
    2: [
      Object.assign({}, emptyWorkGroupRecord),
    ],
    3: [
      Object.assign({}, emptyWorkGroupRecord),
    ],
  },
  topicName: {
    1: 'Topic 1 Work Group',
    2: 'Topic 2 Work Group',
    3: 'Topic 3 Work Group',
  },
};

const actions = {
  updateCityName({ commit }, { value }) {
    commit('updateCityName', { value });
  },
  addRoster({ commit }) {
    commit('addRoster');
  },
  editRoster({ commit }, { id, prop, value }) {
    commit('editRoster', { id, prop, value });
  },
  deleteRoster({ commit }, { id }) {
    commit('deleteRoster', { id });
  },
  addBackboneRoster({ commit }) {
    commit('addBackboneRoster');
  },
  editBackboneRoster({ commit }, { id, prop, value }) {
    commit('editBackboneRoster', { id, prop, value });
  },
  deleteBackboneRoster({ commit }, { id }) {
    commit('deleteBackboneRoster', { id });
  },
  addPartnerRoster({ commit }) {
    commit('addPartnerRoster');
  },
  editPartnerRoster({ commit }, { id, prop, value }) {
    commit('editPartnerRoster', { id, prop, value });
  },
  deletePartnerRoster({ commit }, { id }) {
    commit('deletePartnerRoster', { id });
  },
  addWorkGroupEntry({ commit }, { groupId }) {
    commit('addWorkGroupEntry', { groupId });
  },
  editWorkGroupEntry({ commit }, { groupId, id, prop, value }) {
    commit('editWorkGroupEntry', { groupId, id, prop, value });
  },
  deleteWorkGroupEntry({ commit }, { groupId, id }) {
    commit('deleteWorkGroupEntry', { groupId, id });
  },
  updateTopicName({ commit }, { id, value }) {
    commit('updateTopicName', { id, value });
  },
};

const mutations = {
  updateCityName(state, { value }) {
    state.cityName = value;
  },
  addRoster(state) {
    state.roster.push(Object.assign({}, emptyRosterRecord));
  },
  editRoster(state, { id, prop, value }) {
    state.roster[id][prop] = value;
  },
  deleteRoster(state, { id }) {
    state.roster.splice(id, 1);
  },
  addBackboneRoster(state) {
    state.backboneRoster.push(Object.assign({}, emptyBackboneRosterRecord));
  },
  editBackboneRoster(state, { id, prop, value }) {
    state.backboneRoster[id][prop] = value;
  },
  deleteBackboneRoster(state, { id }) {
    state.backboneRoster.splice(id, 1);
  },
  addPartnerRoster(state) {
    state.partnersRoster.push(Object.assign({}, emptyPartnersRecord));
  },
  editPartnerRoster(state, { id, prop, value }) {
    state.partnersRoster[id][prop] = value;
  },
  deletePartnerRoster(state, { id }) {
    state.partnersRoster.splice(id, 1);
  },
  addWorkGroupEntry(state, { groupId }) {
    state.workGroups[groupId].push(Object.assign({}, emptyWorkGroupRecord));
  },
  editWorkGroupEntry(state, { groupId, id, prop, value }) {
    state.workGroups[groupId][id][prop] = value;
  },
  deleteWorkGroupEntry(state, { groupId, id }) {
    state.workGroups[groupId].splice(id, 1);
  },
  updateTopicName(state, { id, value }) {
    state.topicName[id] = value;
  },
};

export default {
  namespaced: true,
  state: moduleState,
  actions,
  mutations,
};
