import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { title: 'Home | Mosaic Village' },
  },
  {
    path: '/get-ready',
    name: 'GetReady',
    component: () => import(/* webpackChunkName: "getready" */ '../views/GetReady.vue'),
    meta: { title: 'Get Ready | Mosaic Village' },
  },
  {
    path: '/gather-support',
    name: 'GatherSupport',
    component: () => import(/* webpackChunkName: "gathersupport" */ '../views/GatherSupport.vue'),
    meta: { title: 'Gather Support | Mosaic Village' },
  },
  {
    path: '/core-team',
    name: 'CoreTeam',
    component: () => import(/* webpackChunkName: "coreteam" */ '../views/CoreTeam.vue'),
    meta: { title: 'Core Team / Backbone Support | Mosaic Village' },
  },
  {
    path: '/youth-action-council',
    name: 'YouthActionCouncil',
    component: () => import(/* webpackChunkName: "youthactioncouncil" */ '../views/YouthActionCouncil.vue'),
    meta: { title: 'Youth Action Council | Mosaic Village' },
  },
  {
    path: '/common-agenda',
    name: 'CommonAgenda',
    component: () => import(/* webpackChunkName: "commonagenda" */ '../views/CommonAgenda.vue'),
    meta: { title: 'Common Agenda / Shared Measurement | Mosaic Village' },
  },
  {
    path: '/action-plan',
    name: 'ActionPlan',
    component: () => import(/* webpackChunkName: "actionplan" */ '../views/ActionPlan.vue'),
    meta: { title: 'Action Plan | Mosaic Village' },
  },
  {
    path: '/brand',
    name: 'BrandGuidelineTest',
    component: () => import(/* webpackChunkName: "brand" */ '../views/BrandGuidelineTest.vue'),
    meta: { title: 'Brand Guideline Test | Mosaic Village' },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "brand" */ '../views/Dashboard.vue'),
    meta: { title: 'Coming Attractions | Mosaic Village' },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.title === undefined) {
    to.meta.title = 'Mosaic Village';
  }
  document.title = to.meta.title;
  next();
});

export default router;
