const emptyRecord = {
  goal: '',
  proposedMeasure: '',
  indicatorType: 0,
  existingData: 0,
  comparativeDataAvailable: 0,
  possibleDataSources: '',
  researchBased: 0,
};

const moduleState = {
  initiativeLevelIndicatorsList: [
    Object.assign({}, emptyRecord),
  ],
  actionPlanLevelIndicatorsList: [
    Object.assign({}, emptyRecord),
  ],
  contextualIndicatorsList: [
    Object.assign({}, emptyRecord),
  ],
  dataSources: {
    initiativeLevelIndicatorsList: [],
    actionPlanLevelIndicatorsList: [],
    contextualIndicatorsList: [],
  },
  comparativeDataSources: {
    initiativeLevelIndicatorsList: [],
    actionPlanLevelIndicatorsList: [],
    contextualIndicatorsList: [],
  },
  researchCitations: {
    initiativeLevelIndicatorsList: [],
    actionPlanLevelIndicatorsList: [],
    contextualIndicatorsList: [],
  },
  indicatorTypeOpts: [
    'Output',
    'Process',
    'Outcome',
  ],
  existingDataOpts: [
    'Yes',
    'No',
    'Not Sure',
  ],
  comparativeDataAvailableOpts: [
    'Yes',
    'No',
    'Not Sure',
  ],
  researchBasedOpts: [
    'Yes',
    'No',
  ],
  commonAgendaStatement: 'Prevent or limit the duration of youth homelessness through a youth-informed approach that connects existing resources and meets real-time needs to help kids stay in school and off the streets',
  commonAgendaWeServe: 'Unaccompanied minors experiencing housing instability or homelessness who are still in school (or ready to return to school).',
  listRecords: {
    whowhy: [
      {
        col1: 'Unaccompanied minors',
        col2: 'Young people experiencing homelessness without the care of a parent or guardian are at particular risk of exploitation, substance abuse and assault, leading to lasting trauma and complicating their ability to get and stay off the streets.',
      },
      {
        col1: 'Housing unstable or homeless',
        col2: 'This approach has the greatest impact during the late prevention/early intervention period [before or soon after youth become unhoused] because it seeks to prevent or limit exposure to experiences that complicate intervention, aggravate trauma, and increase the risk of chronic homelessness.',
      },
      {
        col1: 'In school',
        col2: 'Keeping youth in school is a vitally important because it:\n' +
              '- Keeps students on track for graduation and a productive and self-sufficient future\n' +
              '- Connects them to guidance and resources, and to adults and peers who care about their wellbeing.',
      },
    ],
    howwhy: [
      {
        col1: 'Conveying, not competing',
        col2: 'This initiative is about supporting rather than competing with existing service providers who are already can feel stretched thin.',
      },
      {
        col1: 'Connecting existing resources',
        col2: 'Rather than duplicating efforts, we can increase capacity by actively sharing information about what’s already happening, then looking for gaps and overlaps.',
      },
      {
        col1: 'Partnerships across organizations and sectors',
        col2: 'Complex social issues can’t be solved by individual agencies or organizations; they call for a community-wide approach.',
      },
      {
        col1: 'Informed by youth with lived experience',
        col2: 'Even more important than knowing what services are provided is knowing how those services are received—and perceived—by those they are intended to benefit. Such information is critical to improving access and effectiveness.',
      },
      {
        col1: 'Using partners\' varied and specialized expertise',
        col2: 'No single partner needs to do—or know— everything. Our community has deep and broad experience to contribute to our shared goals.',
      },
      {
        col1: 'Co-create with partners and youth leaders',
        col2: 'Powerful creative problem-solving methodologies such as design thinking are fueled by bringing diverse thinkers together and by actively engaging—designing with rather than for—users of the service.',
      },
      {
        col1: 'Rapidly meet kids\'s needs',
        col2: 'Quickly removing barriers and meeting needs can keep a young person in school, and help repair trust eroded by hardship.',
      },
    ],
    doby: [
      {
        col1: 'Convening, not competing.',
        col2: null,
      },
      {
        col1: 'Connecting existing services and forging partnerships across organizations and sectors.',
        col2: null,
      },
      {
        col1: 'Drawing on the insights of youth with lived experience.',
        col2: null,
      },
      {
        col1: 'Using the varied and specialized expertise of partners and youth leaders to co-create innovative ways to fill gaps within existing systems, and rapidly meet kids’ real-time and urgent needs.',
        col2: null,
      },
    ],
  },
};

const actions = {
  addRecord({ commit }, { type }) {
    commit('addRecord', { type });
  },
  updateRecord({ commit }, { type, id, prop, value }) {
    commit('updateRecord', { type, id, prop, value });
  },
  deleteRecord({ commit }, { type, id }) {
    commit('deleteRecord', { type, id });
  },
  updateDataSource({ commit }, { type, id, value }) {
    commit('updateDataSource', { type, id, value });
  },
  updateComparativeDataSource({ commit }, { type, id, value }) {
    commit('updateComparativeDataSource', { type, id, value });
  },
  updateResearchCitation({ commit }, { type, id, value }) {
    commit('updateResearchCitation', { type, id, value });
  },
  updateCommonAgendaStatement({ commit }, { value }) {
    commit('updateCommonAgendaStatement', { value });
  },
  updateCommonAgendaWeServe({ commit }, { value }) {
    commit('updateCommonAgendaWeServe', { value });
  },
  addListRecord({ commit }, { type }) {
    commit('addListRecord', { type });
  },
  deleteListRecord({ commit }, { type, id }) {
    commit('deleteListRecord', { type, id });
  },
  updateListRecord({ commit }, { type, id, prop, value }) {
    commit('updateListRecord', { type, id, prop, value });
  },
};

const mutations = {
  addRecord(state, { type }) {
    state[type].push(Object.assign({}, emptyRecord));
  },
  updateRecord(state, { type, id, prop, value }) {
    state[type][id][prop] = value;
  },
  deleteRecord(state, { type, id }) {
    if (state.dataSources[type][id] !== undefined) {
      state.dataSources[type].splice(id, 1);
    }
    if (state.comparativeDataSources[type][id] !== undefined) {
      state.comparativeDataSources[type].splice(id, 1);
    }
    if (state.researchCitations[type][id] !== undefined) {
      state.researchCitations[type].splice(id, 1);
    }
    state[type].splice(id, 1);
  },
  updateDataSource(state, { type, id, value }) {
    state.dataSources[type][id] = value;
  },
  updateComparativeDataSource(state, { type, id, value }) {
    state.comparativeDataSources[type][id] = value;
  },
  updateResearchCitation(state, { type, id, value }) {
    state.researchCitations[type][id] = value;
  },
  updateCommonAgendaStatement(state, { value }) {
    state.commonAgendaStatement = value;
  },
  updateCommonAgendaWeServe(state, { value }) {
    state.commonAgendaWeServe = value;
  },
  addListRecord(state, { type }) {
    state.listRecords[type].push({
      col1: '',
      col2: '',
    });
  },
  deleteListRecord(state, { type, id }) {
    state.listRecords[type].splice(id, 1);
  },
  updateListRecord(state, { type, id, prop, value }) {
    state.listRecords[type][id][prop] = value;
  },
};

export default {
  namespaced: true,
  state: moduleState,
  actions,
  mutations,
};
