const moduleState = {
  resourceList: [
    {
      need: null,
      urgency: 0,
      type: 0,
      sourceType: 0,
      potentialSource: null,
      status: 0,
    },
  ],
};

const actions = {
  addResource({ commit }) {
    commit('addResource');
  },
  deleteResource({ commit }, { id }) {
    commit('deleteResource', { id });
  },
  updateResource({ commit }, { id, prop, value }) {
    commit('updateResource', { id, prop, value });
  },
};

const mutations = {
  addResource(state) {
    state.resourceList.push({
      need: null,
      urgency: 0,
      type: 0,
      sourceType: 0,
      potentialSource: null,
      status: 0,
    });
  },
  deleteResource(state, { id }) {
    state.resourceList.splice(id, 1);
  },
  updateResource(state, { id, prop, value }) {
    state.resourceList[id][prop] = value;
  },
};

export default {
  namespaced: true,
  state: moduleState,
  actions,
  mutations,
};
