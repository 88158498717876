import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vClickOutside from 'click-outside-vue3';

import 'bootstrap';
import 'bootstrap-icons/font/bootstrap-icons.css';

createApp(App).use(vClickOutside).use(store).use(router).mount('#app');
