import partners from './partners.module';
import readinessAssessment from './readinessAssessment.module';
import resources from './resources.module';
import measurement from './measurement.module';
import actionPlan from './actionPlan.module';
import youthAssessment from './youthAssessment.module';
import teamRoster from './teamRoster.module';
import VuexPersistence from 'vuex-persist';
import { createStore } from 'vuex';

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    readinessAssessment,
    partners,
    resources,
    measurement,
    actionPlan,
    youthAssessment,
    teamRoster,
  },
  plugins: [new VuexPersistence().plugin],
});
