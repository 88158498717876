<template>
  <div class="container py-5">
    <div class="row gx-1 d-flex justify-content-center align-items-start">
      <h1 class="visually-hidden">Mosaic Village</h1>
      <div class="col-md-12 col-lg-6 mb-md-3">
        <div class="container-fluid">
          <div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/647559232?h=910ba26a2e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Intro Video"></iframe></div>
        </div>
      </div>
      <div class="col-md-12 col-lg-6 text-left mb-md-3">
        <h2 class="h2">What is Mosaic Village?</h2>
        <p class="p">Inspired by the groundbreaking work of <a href="https://www.15thnight.org/" title="15th Night organization website">The 15th Night</a> in Eugene, Oregon, Mosaic Village helps communities build a youth-informed, community-powered system to help young people experiencing housing instability to stay in school and off the streets. Mosaic Village’s efficient approach connects existing services and forges partnerships across sectors to fill gaps within existing systems, and rapidly meet kids’ real-time and urgent needs.</p>
        <p class="p">The Mosaic Village platform takes you step-by-step through the process of building your community’s initiative, offering guidance and interactive tools you can start using today.
        </p>
      </div>
      </div>
    <div class="row">
      <div class="col-12 text-left mb-md-3">
        <h2 class="h2">How the Mosaic Village platform works</h2>
        <p class="p">Building your community’s Mosaic Village is a multi-step process. The Mosaic Village platform guides you along the path, giving you interactive and interconnected tools to support your work at each step. Here are the steps you’ll take to launch your initiative:</p>
        <div class="mv-slider-container w-100 my-1 d-flex align-items-center justify-content-center">
          <div id="how-it-works-slider" class="mv-slider carousel carousel-dark slide" data-bs-wrap="false">
            <div class="mv-slider__indicators carousel-indicators mt-3 mb-1">
              <button type="button" data-bs-target="#how-it-works-slider" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Get Ready step explanation"></button>
              <button type="button" class="mv-slider__dot" data-bs-target="#how-it-works-slider" data-bs-slide-to="1" aria-label="Gathering Support step explanation"></button>
              <button type="button" data-bs-target="#how-it-works-slider" data-bs-slide-to="2" aria-label="Build core team and secure backbone support step explanation"></button>
              <button type="button" data-bs-target="#how-it-works-slider" data-bs-slide-to="3" aria-label="Youth Action Council step explanation"></button>
              <button type="button" data-bs-target="#how-it-works-slider" data-bs-slide-to="4" aria-label="Develop common agenda and shared measurement step explanation"></button>
              <button type="button" data-bs-target="#how-it-works-slider" data-bs-slide-to="5" aria-label="Start your action plan step explanation"></button>
            </div>
            <div class="mv-slider__inner carousel-inner d-flex align-items-center mx-auto">
              <!-- Get Ready Explainer Slide -->
              <div class="mv-slider__item carousel-item active" id="get-ready-slide" data-bs-interval="false">
                <div class="mv-slider__item-content align-items-center">
                  <div class="row gx-2 justify-content-between w-100">
                    <div class="col-3 d-flex align-items-center">
                      <router-link
                        to="/get-ready"
                        id="get-ready-step"
                        class="stepping-stones__stone px-5"
                      >
                        <span class="stepping-stones__text">get ready</span>
                        <img class="stepping-stones__img" src="../../public/img/stones/get-ready-blank.png" alt="Get ready">
                      </router-link>
                    </div>
                    <div class="col-8 d-flex flex-column justify-content-center mv-slider__content-col">
                      <p class="p" aria-hidden="true">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</p>
                      <p class="p">
                        This step helps you identify what assets your community already has and learn about what still needs to be developed. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      </p>
                      <p class="p" aria-hidden="true">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</p>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Gather Support Explainer Slide -->
              <div class="mv-slider__item carousel-item" id="gather-support-slide" data-bs-interval="1000000">
                <div class="mv-slider__item-content align-items-center">
                  <div class="row gx-2 justify-content-between w-100">
                    <div class="col-3 d-flex align-items-center">
                      <router-link
                        to="/gather-support"
                        id="gather-support-step"
                        class="stepping-stones__stone"
                      >
                        <span class="stepping-stones__text">gather support</span>
                        <img class="stepping-stones__img" src="../../public/img/stones/gather-support-blank.png" alt="Gather Support">
                      </router-link>
                    </div>
                    <div class="col-8 d-flex flex-column justify-content-center mv-slider__content-col">
                      <p class="p">
                        This step walks you through one of the most important and rewarding parts of building your Mosaic Village initiative: reaching out to and consulting with people in your community to:
                        <ul>
                          <li>Better understand their varying needs, interests and perceptions.</li>
                          <li>Identify people who will champion your initiative and serve as key team members.</li>
                          <li>Recruit partners and develop allies across organizations and sectors.</li>
                        </ul>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Core Team Explainer Slide -->
              <div class="mv-slider__item carousel-item" id="core-team-slide" data-bs-interval="1000000">
                <div class="mv-slider__item-content align-items-center">
                  <div class="row gx-2 justify-content-between w-100">
                    <div class="col-3 d-flex align-items-center">
                      <router-link
                        to="/core-team"
                        id="build-team-step"
                        class="stepping-stones__stone"
                      >
                        <span class="stepping-stones__text">build core team <br> and secure <br> backbone support</span>
                        <img class="stepping-stones__img" src="../../public/img/stones/build-team-blank.png" alt="Build core team and secure backbone support">
                      </router-link>
                    </div>
                    <div class="col-8 d-flex flex-column justify-content-center mv-slider__content-col">
                      <p class="p">
                        This step builds on the connections you made in the Gather Support step and helps you shape the team you’ll need to be successful. Tools include:
                        <ul>
                          <li>Needs and Assessment Checklist, to help you identify and track what you have and what you need.</li>
                          <li>Team Roster, to document all participants, including leadership, staff, backbone support, partners and work groups.</li>
                          <li>Community Coordinator job description to help you recruit the perfect candidate for your initiative’s most important staff role.</li>
                        </ul>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Youth Action Council Explainer Slide -->
              <div class="mv-slider__item carousel-item" id="yac-slide" data-bs-interval="1000000">
                <div class="mv-slider__item-content align-items-center">
                  <div class="row gx-2 justify-content-between w-100">
                    <div class="col-3 d-flex align-items-center">
                      <router-link
                        to="/youth-action-council"
                        id="create-yac-step"
                        class="stepping-stones__stone"
                      >
                        <span class="stepping-stones__text">create <br> youth action council</span>
                        <img class="stepping-stones__img" src="../../public/img/stones/create-yac-blank.png" alt="Create youth action council">
                      </router-link>
                    </div>
                    <div class="col-8 d-flex flex-column justify-content-center mv-slider__content-col">
                      <p class="p">In this step you’ll learn why the Youth Action Council (YAC) is the heart of every Mosaic Village initiative, and how to get yours up and running.</p>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Common Agenda Explainer Slide -->
              <div class="mv-slider__item carousel-item" id="common-agenda-slide" data-bs-interval="1000000">
                <div class="mv-slider__item-content align-items-center">
                  <div class="row gx-2 justify-content-between w-100">
                    <div class="col-3 d-flex align-items-center">
                      <router-link
                        to="/common-agenda"
                        id="develop-agenda-step"
                        class="stepping-stones__stone"
                      >
                        <span class="stepping-stones__text">develop <br> common agenda and <br> shared measurement</span>
                        <img class="stepping-stones__img" src="../../public/img/stones/develop-agenda-blank.png" alt="Develop common agenda and shared measurement">
                      </router-link>
                    </div>
                    <div class="col-8 d-flex flex-column justify-content-start mv-slider__content-col">
                      <p class="p">This step includes tools to support two critical elements of your initiative: the common agenda and shared measurement.</p>
                      <p class="p">The common agenda defines the specific goals of the effort, identifying precisely what will and won’t be included. This process can be challenging for new initiatives, but the Mosaic Village platform makes it a little easier because it comes with a default Common Agenda Framework based on the work of our model organization, The 15th Night. While your community may choose to adjust the focus, or expand or narrow the defined boundaries of the framework, you have a solid starting place for your work, and a rationale for those boundaries.</p>
                      <p class="p">Shared measurement is a key component of successful collective impact initiatives because agreeing on <span class="fw-bold">what</span> specifically will be measured and <span class="fw-bold">how</span> it will be measured keeps everyone on the same page. The Shared Measurement and Data Source Worksheet helps your team develop your initial shared measurement system, and continues to be a useful tool as you implement new programs and strategies.</p>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Action Plan Explainer Slide -->
              <div class="mv-slider__item carousel-item" id="action-plan-slide" data-bs-interval="1000000">
                <div class="mv-slider__item-content">
                  <div class="row gx-2 justify-content-between w-100">
                    <div class="col-3 d-flex align-items-center">
                      <router-link
                        to="/action-plan"
                        id="start-action-plan-step"
                        class="stepping-stones__stone"
                      >
                        <span class="stepping-stones__text">start your <br> action plan</span>
                        <img class="stepping-stones__img" src="../../public/img/stones/start-action-plan-blank.png" alt="Start your action plan">
                      </router-link>
                    </div>
                    <div class="col-8 d-flex flex-column justify-content-center mv-slider__content-col">
                        <p class="p">This step helps you develop your action plan. Action planning is not a process that you start and finish; your action plan documents an ongoing process that keeps you focused and moving forward. The Mosaic Village Action Planner helps you develop, organize and manage critical components, and track the action steps you undertake to achieve your plan’s goals.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#how-it-works-slider" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#how-it-works-slider" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container py-5">
    <div class="row">
      <div class="col">
        <router-link
          tag="button"
          class="btn btn-primary float-end"
          to="/get-ready">
          <i class="bi bi-chevron-right"></i>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'HomePage',
};
</script>

<style lang="scss" scoped>
  .btn-info {
    &,
    &:link,
    &:visited {
      color:$light;
      transition:$transition-default;
    }
    &:active,
    &:hover {
      background:darken($info,5%);
      color: $light;
      outline:none;
    }
    &:focus {
      background:darken($info,5%);
      color: $light;
      outline:none;
    }
  }
  .mv-slider {
    height: 350px;
    position:relative;
    &__dot {
      border-radius:100%;
      height:10px;
      width:10px;
    }
    &__inner,
    .carousel-inner {
      position:relative;
      width: 992px;
      width:80%;
    }
    &__item,
    .carousel-item {
      height:350px;
    }
    &__item-content {
      height:300px;
      overflow-x: hidden;
      overflow-y: auto;
    }
    &__content-col {
      height: 300px;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
  .carousel-control-prev,
  .carousel-control-next {
    max-width:90px;
    width: 10%;
  }
  .carousel .carousel-indicators button {
    border-radius:100%;
    height:10px;
    width:10px;
  }
  .carousel-dark .carousel-indicators button {
    &.active {
      background-color: $info;
    }
  }

  .stepping-stones {
    width: $stepping-stones-width;
    &__stone {
      align-items: center;
      cursor: pointer;
      display: flex;
      justify-content: center;
      margin: $stepping-stone-margin + .5rem;
      position: relative;
      width: $stepping-stone-width;
      @include media-breakpoint-up(lg) {
        margin: $stepping-stone-margin-lg + .5rem;
        .stepping-stones__text {
          font-size: $stepping-stone-font-size-lg;
        }
        .stepping-stones__img {
          max-height: $stepping-stone-img-max-height-lg;
        }
      }
      @include media-breakpoint-up(xl) {
        .stepping-stones__text {
          font-size: $stepping-stone-font-size-xl;
        }
        .stepping-stones__img {
          max-height: $stepping-stone-img-max-height-xl;
        }
      }
      &.active {
        filter: $stepping-stone-active-filter;
      }
      &.active .stepping-stones__text {
        color: $black;
      }
    }
    &__img {
      max-height: $stepping-stone-img-max-height;
      min-height: $stepping-stone-img-min-height;
    }
    &__text {
      color: $stepping-stone-text-color;
      cursor: pointer;
      font-size: $stepping-stone-font-size;
      font-weight: $stepping-stone-font-weight;
      position: absolute;
      text-align: center;
    }
    &__link {
      position:relative;
    }
    #get-ready-step {
    }
    #gather-support-step {
      margin-top:18px;
      transform: rotate(-18deg);
      .stepping-stones__text {
        transform: rotate(18deg);
      }
    }
    #build-team-step {
      margin-top: 7px;
    }
    #create-yac-step {
      margin-top: 20px;
      transform: rotate(-3deg);
      .stepping-stones__text {
        transform: rotate(3deg);
      }
    }
    #develop-agenda-step {
      transform: rotate(4deg);
      .stepping-stones__text {
        transform: rotate(-4deg);
      }
    }
    #start-action-plan-step {
      margin-top: 36px;
    }
  }
</style>
