<template>
  <nav class="navbar navbar-expand-lg d-flex flex-row d-print-none" role="navigation" id="navbar">
    <div class="container">
      <router-link to="/" class="navbar-brand">
        <img
          src="../../public/img/logos/mv-logo-horizontal-light.png"
          alt="Mosaic Village logo"
          class="d-inline-block mv-navbrand"
        >
      </router-link>
      <button class="navbar-toggler"
        id="mv-navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbar-content"
        aria-controls="navbar-content"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <i role="img" class="mv-nav-toggle bi-list"></i>
      </button>
      <div class="collapse navbar-collapse justify-content-sm-center justify-content-md-end" id="navbar-content">
        <ul class="navbar-nav flex-md-row flex-sm-column justify-content-md-end justify-content-sm-center w-100" id="nav">
          <li class="nav-item">
            <router-link class="nav-link" to="/">Home</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/get-ready">Get Ready</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/gather-support">Gather Support</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/core-team">Core Team / Backbone Support</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/youth-action-council">Youth Action Council</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/common-agenda">Common Agenda / Shared Measurement</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/action-plan">Action Plan</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/dashboard">Coming Attractions</router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <div class="d-none d-print-block">
    <img
      src="../../public/img/logos/mv-logo-horizontal.png"
      alt="Mosaic Village logo"
      class="d-inline-block mv-navbrand"
    ></div>
</template>

<script>
export default {
  name: 'MainNav',
};
</script>

<style lang="scss" scoped>
#navbar {
  background: $nav-bg-fallback;
  background: $nav-bg;
  position: relative;
  width: $nav-width;
}
.mv-navbrand {
  max-height: $nav-brand-max-height;
  min-height: $nav-brand-min-height;
}
#mv-navbar-toggler {
  &:focus {
    box-shadow:none;
  }
}
.mv-nav-toggle {
  color: $nav-toggle-text-color;
  &::before {
    font-size: $nav-toggle-size;
  }
}
#nav {
  padding: $nav-padding;
  text-align: center;
}
.nav-item {
  padding: $nav-item-padding;
}
#nav a {
  &,
  &:link,
  &:visited {
    color: $nav-link-text-color;
    font-weight: $nav-link-font-weight;
  }
  &:active,
  &:hover {
    color: $nav-link-text-color-hover;
  }
}
#nav a.router-link-exact-active {
  &,
  &:link,
  &:visited {
    color: $nav-link-current-text-color;
  }
}
</style>
