const emptyActionItem = {
  name: null,
  initiatedBy: null,
  completedBy: null,
};

const emptyGoal = {
  strategy: null,
  type: 0,
  ledBy: null,
  supportedBy: null,
  resultsIn: 0,
  initiatedBy: null,
  completedBy: null,
  evidenceBased: 0,
  citations: null,
  actionItems: [
    Object.assign({}, emptyActionItem),
    Object.assign({}, emptyActionItem),
    Object.assign({}, emptyActionItem),
  ],
};

const moduleState = {
  strategicGoals: [
    Object.assign({}, emptyGoal),
    Object.assign({}, emptyGoal),
    Object.assign({}, emptyGoal),
  ],
  typeOpts: ['service', 'coordination', 'policy/advocacy', 'capacity building', 'community', 'engagement'],
  resultsInOpts: ['short term', 'mid term', 'long term'],
  evidenceBasedOpts: ['yes', 'no'],
};

const actions = {
  addStrategicGoal({ commit }) {
    commit('addStrategicGoal');
  },
  deleteStrategicGoal({ commit }, { id }) {
    commit('deleteStrategicGoal', { id });
  },
  updateStrategicGoalProp({ commit }, { id, prop, value }) {
    commit('updateStrategicGoalProp', { id, prop, value });
  },
  addActionPlanItem({ commit }, { goalId }) {
    commit('addActionPlanItem', { goalId });
  },
  deleteActionPlanItem({ commit }, { goalId, id }) {
    commit('deleteActionPlanItem', { goalId, id });
  },
  updateActionPlanItemProp({ commit }, { goalId, id, prop, value }) {
    commit('updateActionPlanItemProp', { goalId, id, prop, value });
  },
};

const mutations = {
  addStrategicGoal(state) {
    state.strategicGoals.push(Object.assign({}, emptyGoal));
  },
  deleteStrategicGoal(state, { id }) {
    state.strategicGoals.splice(id, 1);
  },
  updateStrategicGoalProp(state, { id, prop, value }) {
    state.strategicGoals[id][prop] = value;
  },
  addActionPlanItem(state, { goalId }) {
    state.strategicGoals[goalId].actionItems.push(Object.assign({}, emptyActionItem));
  },
  deleteActionPlanItem(state, { goalId, id }) {
    state.strategicGoals[goalId].actionItems.splice(id, 1);
  },
  updateActionPlanItemProp(state, { goalId, id, prop, value }) {
    state.strategicGoals[goalId].actionItems[id][prop] = value;
  },
};

export default {
  namespaced: true,
  state: moduleState,
  actions,
  mutations,
};
